import React, { useEffect } from "react"
import SbEditable from "storyblok-react"
import { Helmet } from "react-helmet"
import KlaroConsentInfo from "../modules/KlaroConsentInfo"
import useKlaroConsentable from "../../hooks/useKlaroConsentable"
import { useTranslation } from "react-i18next"

const KLARO_OXOMI_APP_KEY = "oxomi"

const OxomiBlock = ({ block }) => {
  const { usageConsented, appInfo, handleKlaroConsent } =
    useKlaroConsentable(KLARO_OXOMI_APP_KEY)
  const { i18n } = useTranslation()

  const oxomi_ready = () => {
    // Initialisierung
    oxomi.init({
      portal: "3000490",
      lang: i18n.language,
    })

    // Funktionsaufruf, um Portaloberfläche anzuzeigen
    oxomi.portalSearch({
      target: "#output",
      input: "#input",
      lang: i18n.language,
      showDetails: true,
    })
  }

  useEffect(() => {
    if (usageConsented) {
      setTimeout(() => {
        if (oxomi !== undefined) {
          oxomi_ready()
        }
      }, 1000)
    }
  }, [usageConsented])

  return (
    <>
      {usageConsented === false && appInfo ? (
        <KlaroConsentInfo
          appInfo={appInfo}
          handleKlaroConsent={handleKlaroConsent}
          renderWrapper={true}
        />
      ) : (
        <>
          <Helmet>
            <script
              type="text/javascript"
              src="https://oxomi.com/assets/frontend/oxomi.js"
            ></script>
          </Helmet>
          <SbEditable content={block}>
            <section className="py-4">
              <div className="mx-auto max-w-screen-xl px-grid">
                {typeof window !== "undefined" && window.storyblok?.inEditor && (
                  <div
                    className="py-2 mb-8 rounded px-grid"
                    style={{ backgroundColor: "#09b3af" }}
                  >
                    <p className="font-medium prose text-white">
                      <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
                      <b>OXOMI Block:</b> Externe- bzw. Fremdscripte, wie der
                      OXOMI-Code, können unter Umständen aus technischen Gründen
                      in der <i>Vorschau</i> nicht korrekt angezeigt werden.
                    </p>
                  </div>
                )}
                <div id="input"></div>
                <div id="output">Daten werden geladen…</div>
              </div>
            </section>
          </SbEditable>
        </>
      )}
    </>
  )
}

export default OxomiBlock
